/**
 * @format
 */

import { REGEX_LIB } from './constants';

/**
 * Validates a phone number checking it has only numbers and its length
 * @param {String} value - Phone value
 *
 * Function that validates if mobile phone has 5 digits, ignoring spaces
 */
export const validatePhone = value => REGEX_LIB.phoneNumber.test(value);
