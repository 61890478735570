/**
 * @format
 */

import tippy from 'tippy.js';
import isPromise from '../utils/isPromise';

/**
 * Creates a tooltip sequence
 *
 * @param {array} sequence - array of objects containing each tooltip object.
 * @param {object} options
 *
 * - Structure
 * new tooltipSequence(sequence, options)
 *
 *
 *  ===== Options =====
 *
 * - showOnInit {Boolean} - if true shows first tooltip right after sequence creation. True by default.
 * - mandatory {Boolean} - if true sequence is mandatory, so it cannot be closed. False by default.
 * - nextButton {string} - next button selector. '.js-tooltip-nav-next' by default.
 * - prevButton {string} - prev button selector. '.js-tooltip-nav-prev' by default.
 * - scrollIntoView {Boolean} - if true scrolls tooltip into view on show.
 * - scrollOffset {number} - top offset after scroll
 * - mobileBreakpoint {number} - mobile first breakpoint in px
 * - centerOnMobile {Boolean} - forces tippy mobile center
 * - beforeChange {function or promise} - will be executed before tooltip change. If promise waits to be resolved after change to next tooltip.
 * - onStart {function} - will be executed at the beginning of sequence.
 * - onComplete {function} - will be executed at the end of sequence.
 * - onInterrupt {function} - will be executed when user interrupts sequence (clicks outside tooltip or closes tooltip).
 * - onTooltipShow {function} - will be executed when a tooltip is shown
 *
 *
 *  ===== Initialization =====
 *
 * - Example
 * const exampleSequence = [{
 *    selector: '.js-tutorial-tooltip-trendsetter',
 *    htmlContentSelector: '#tutorial-tooltip-trendsetter-template',
 *    placement: 'top',
 *  },
 *  {
 *    selector: '.js-tutorial-tooltip-fastbooking',
 *    htmlContentSelector: '#tutorial-tooltip-fastbooking-template',
 *    placement: 'top',
 *  }]
 *
 * const sequenceInstance = new tooltipSequence(exampleSequence, {
 *  nextButton: '.js-my-next-button',
 *  prevButton: '.js-my-prev-button',
 *  onComplete: () => {
 *    alert('Sequence end!');
 *  },
 * })
 *
 *
 * ===== Methods =====
 *
 * - sequenceInstance.start() - Starts the sequence at the beginning
 * - sequenceInstance.stop() - Stops the sequence at any point
 * - sequenceInstance.next() - Goes to next tooltip
 * - sequenceInstance.prev() - Goes to previous tooltip
 * - sequenceInstance.goToTooltip(index) - Goes to specified tooltip by index
 * - sequenceInstance.setMandatory(boolean) - sets a sequence as mandatory
 */

export default function tooltipSequence(sequence = [], options) {
  let currentTooltipIndex = -1;
  let defaultOptions = {
    showOnInit: true,
    mandatory: false,
    nextButton: '.js-tooltip-nav-next',
    prevButton: '.js-tooltip-nav-prev',
    scrollIntoView: true,
    scrollOffset: 0,
    mobileBreakpoint: 767,
    centerOnMobile: false,
  };

  options = { ...defaultOptions, ...options };

  if (!sequence.length) {
    throw new Error('Tooltip sequence: Sequence property is required');
  }

  const isMobile = window.innerWidth <= options.mobileBreakpoint;

  const getElement = selector => document.querySelector(selector);

  this.init = () => {
    sequence.forEach(({ selector, htmlContentSelector, placement }, index) => {
      const template = document.querySelector(htmlContentSelector);
      const element = getElement(selector);

      const mandatoryProps = getTooltipMandatoryProps(this, options.mandatory);

      if (element && template) {
        prepareControls(htmlContentSelector);

        const tooltip = tippy(selector, {
          arrow: true,
          allowHTML: true,
          trigger: 'manual',
          interactive: true,
          placement,
          ...mandatoryProps,
          ...(isMobile &&
            options.centerOnMobile && {
              getReferenceClientRect: () => {
                let reference = element.getBoundingClientRect();
                const rect = {};

                // Loops through all DomRect properties. Cannot spread because they're not enumerable.
                for (const prop in reference) {
                  rect[prop] = reference[prop];
                }

                return { ...rect, right: 0, left: 0, width: window.innerWidth };
              },
            }),
        });

        const instance = tooltip[0];

        // Set tooltip content
        instance.setContent(template);

        // Save instance
        sequence[index].tooltipInstance = instance;
      } else {
        // Set to null in sequence array to not interrupt loop
        sequence[index] = null;
      }

      // Set mandatory class
      template.classList.toggle('is-mandatory', options.mandatory);
    });

    // Remove nullish values
    sequence = sequence.filter(Boolean);

    options.showOnInit && this.start();
  };

  this.setMandatory = mandatory => {
    if (options.mandatory === mandatory) return;

    options.mandatory = mandatory;

    sequence.forEach(({ tooltipInstance, htmlContentSelector }) => {
      const template = tooltipInstance.popper.querySelector(htmlContentSelector);

      template.classList.toggle('is-mandatory', mandatory);

      tooltipInstance.setProps(getTooltipMandatoryProps(mandatory));
    });
  };

  const getTooltipMandatoryProps = (tooltipInstance, mandatory) => ({
    hideOnClick: !mandatory,
    ...(options.onInterrupt &&
      !mandatory && {
        onClickOutside: () => {
          options.onInterrupt({ instance: tooltipInstance, lastIndex: currentTooltipIndex });
        },
      }),
  });

  const show = index => {
    options.onTooltipShow && options.onTooltipShow({ instance: this, activeIndex: index, lastIndex: currentTooltipIndex });

    sequence[index].tooltipInstance.show();

    currentTooltipIndex = index;
  };

  const hide = index => {
    sequence[index].tooltipInstance.hide();
  };

  const hideCurrent = () => {
    currentTooltipIndex !== -1 && hide(currentTooltipIndex);
  };

  const scrollIntoView = currentTooltip => {
    const { selector } = currentTooltip;
    const elem = getElement(selector);
    const y = elem.getBoundingClientRect().top + window.pageYOffset + options.scrollOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  this.start = () => {
    options.onStart && options.onStart(this);
    this.goToTooltip(0);
  };

  this.stop = () => {
    if (options.mandatory) return;

    options.onInterrupt && options.onInterrupt({ instance: this, lastIndex: currentTooltipIndex });

    hideCurrent();
  };

  this.next = () => {
    this.goToTooltip(currentTooltipIndex + 1);
  };

  this.prev = () => {
    this.goToTooltip(currentTooltipIndex - 1);
  };

  this.goToTooltip = index => {
    hideCurrent();

    if (index === sequence.length) {
      options.onComplete && options.onComplete(this);
      currentTooltipIndex = -1;
      return;
    }

    if (index < 0 || index >= sequence.length) return;

    const beforeChange = options.beforeChange && options.beforeChange(currentTooltipIndex, index);

    if (isPromise(beforeChange)) {
      beforeChange.then(() => {
        options.scrollIntoView && scrollIntoView(sequence[index]);
        show(index);
      });
    } else {
      options.scrollIntoView && scrollIntoView(sequence[index]);
      show(index);
    }
  };

  const prepareControls = htmlContentSelector => {
    const tooltipCloseBtns = document.querySelectorAll(`${htmlContentSelector} .js-onboarding-tooltip-close`);
    const tooltipNavNextButtons = document.querySelectorAll(`${htmlContentSelector} ${options.nextButton}`);
    const tooltipNavPrevButtons = document.querySelectorAll(`${htmlContentSelector} ${options.prevButton}`);

    tooltipCloseBtns.forEach(closeBtn => {
      closeBtn.addEventListener('click', this.stop);
    });

    tooltipNavNextButtons.forEach(navNextBtn => {
      navNextBtn.addEventListener('click', this.next);
    });

    tooltipNavPrevButtons.forEach(navPrevBtn => {
      navPrevBtn.addEventListener('click', this.prev);
    });
  };

  this.init();
}
