// import '../../shared/deadline/deadline';

function init() {
  let $el = $('.home-masonry-offers');
  let visible_club_elements = $el.find('[data-visible-to-the-club-user="false"]:not(.hidden)').toArray();
  let hidden_elements = $el.find('.block.offer.hidden').not('[data-visible-to-the-club-user="false"]').toArray();

  // $el.find('[data-deadline]').deadline({
  //   seconds: 1
  // });

  // Comprobamos si la cookie existe
  if ($.cookie('user_the_club') === undefined) return false;
  // Comprobamos si hay bloques que tengamos que ocultar
  if (!visible_club_elements.length) return false;

  // let total_init = $el.find('.block.offer:not(.hidden)').length;
  // // Guardamos las clases de tipo de bloque
  // let ob_class = { 2: 'double', 3: 'triple', 4: 'quadruple', 5: 'full' };
  // let diff = visible_club_elements.length - hidden_elements.length;
  // let old_block_class = ob_class[total_init];
  // // Reemplazamos o eliminamos los bloques que no se tienen que ver
  // $.each(visible_club_elements, function (index, value) {
  //   let $old_element = $(value);
  //   let $new_element = $(hidden_elements[index]);

  //   if ($new_element === undefined) {
  //     $old_element.remove();
  //   } else {
  //     $old_element.replaceWith($new_element.removeClass('hidden'));
  //   }
  // });

  // let $first_block = $el.find('.' + old_block_class).find('> div').first();

  // if (!hidden_elements.length && !$first_block.find('.block.offer').length) {
  //   $first_block.append($el.find('[data-visible-to-the-club-user="true"]').first());
  // }

  // // Si tenemos menos bloques hidden que los que tenemos que reemplazar
  // // cambiamos la clase del bloque padre para reestructurar todo
  // if (diff > 0) total_init = total_init - diff;

  // $el.find('.' + old_block_class).removeClass(old_block_class).addClass(ob_class[total_init]);
}

document.addEventListener('DOMContentLoaded', function () {
  init();
}, false);
