/**
 * @format
 */

const $body = document.querySelector('body');
let $loaderBlock = document.querySelector('.js-loader');
const SPINNER_ALLOWED_COLORS = ['primary', 'grey'];

/**
 * Loader spinner management
 *
 * @param {object} [container] - container where spinner will be appended
 * @param {string} [title] - title of the loader
 * @param {string} [subtitle] - subtitle of the loader
 * @param {string} [extraClass] - class to add to loader container
 * @param {number} [spinnerSize] - size of the spinner in px
 * @param {string} [spinnerColor='grey'] - color of the spinner (must be in SPINNER_ALLOWED_COLORS), grey by default.
 *
 * @example
 * // Show loaderSpinner in a specific container
 * loaderSpinner.show({
 *  container: document.querySelector('.js-booking-container')
 *  title: 'Wait a second',
 *  subtitle: 'We are looking for the best price',
 *  spinnerSize: 200,
 *  spinnerColor: 'grey'
 * })
 *
 * // Hide loaderSpinner shown in a specific container
 * loaderSpinner.hide({
 *  container: document.querySelector('.js-booking-container')
 * })
 *
 * // Show loaderSpinner in body at fullscreen (no container)
 * loaderSpinner.show()
 *
 * // Hide any loaderSpinner in page
 * loaderSpinner.hide()
 */

const loaderSpinner = () => {
  const show = ({ container = $body, title, subtitle, spinnerSize, spinnerColor, extraClass } = { container: $body }) => {
    $loaderBlock = document.querySelector('.js-loader');

    if (!$loaderBlock) return;

    const $newLoader = $loaderBlock.cloneNode(true);
    const $spinner = $newLoader.querySelector('.js-spinner svg');

    $newLoader.classList.add('js-loader-cloned');
    extraClass && $newLoader.classList.add(...extraClass.split(' '));

    // Set loader style
    $newLoader.classList.add(container === $body ? 'b-loader--fullscreen' : 'b-loader--default');
    // Remove legacy classes
    $newLoader.classList.remove('is-hidden', 'hidden', 'fixed');

    // Update texts
    if (title) {
      const $title = $newLoader.querySelector('.js-loader-title');
      if ($title) {
        $title.classList.remove('is-hidden', 'hidden');
        $title.textContent = title;
      }
    }
    if (subtitle) {
      const $subtitle = $newLoader.querySelector('.js-loader-subtitle');
      if ($subtitle) {
        $subtitle.classList.remove('is-hidden', 'hidden');
        $subtitle.textContent = subtitle;
      }
    }

    // Update spinner
    spinnerSize && ($spinner.style.width = `${spinnerSize}px`) && ($spinner.style.height = `${spinnerSize}px`);
    spinnerColor && SPINNER_ALLOWED_COLORS.includes(spinnerColor) && $spinner.classList.add(`b-loader__spinner--${spinnerColor}`);

    // Append to container
    container.append($newLoader);
  };

  const hide = ({ container = document } = { container: document }) => {
    container.querySelectorAll('.js-loader-cloned').forEach(loaderItem => {
      loaderItem.remove();
    });
  };

  return {
    show,
    hide,
  };
};

export default loaderSpinner();
