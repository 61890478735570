import { sendUtagEvent } from '../../../../../assets/javascripts/analytics/2_utag_events'
import { getCleanedString } from '../../../../../assets/javascripts/analytics/2_utag_events'

export function sendHeadbandUtagEvent(headband) {
  const event_cat = getCleanedString(headband.dataset.eventCat)
  const event_act = getCleanedString((headband.dataset.eventAct || '').toLowerCase())
  let event_lbl = getCleanedString(headband.dataset.eventLbl)

  sendUtagEvent({
    data: {
      event_name: 'offers_apply',
      event_cat,
      event_act,
      event_lbl,
      event_purpose: 'booking',
      event_structure: 'sticky',
    },
  })

  if (event_act === 'promo code') {
    event_lbl = getCleanedString(headband.querySelector('.js-promolink').dataset.promocode)

    sendUtagEvent({
      data: {
        event_name: 'offers_apply',
        event_cat: 'offers',
        event_act: 'apply offers',
        event_lbl,
        event_purpose: 'booking',
        event_structure: 'sticky',
      },
    })
  }

  sendUtagEvent({
    data: {
      event_name: 'headband_module',
      event_cat,
      event_act,
      event_lbl,
      event_purpose: 'information',
      event_structure: 'sticky',
    },
  })
}
