import initPromoLink from '../../shared/promo_link/promo_link'
import countdownClock from '../../utils/countdown'
import { sendHeadbandUtagEvent } from './headband.analytics'

const ACTIVE_STICKY_HEADBANDS_CLASS = '.js-headband.sticky.slide-down'

function showHeadbandCountdown(headband) {
  const now = new Date()
  const countdownStart = new Date(headband.dataset.countdownStart)
  const countdownEnd = new Date(headband.dataset.countdownEnd)
  const countdown = headband.querySelector('.js-countdown')
  const expiredText = JSON.parse(countdown.dataset.translations).expired
  
  if (now >= countdownStart) {
    countdown.classList.remove('hidden')
    countdownClock(1000, 9999)

    if (expiredText === '' && now >= countdownEnd) {
      countdown.remove()
    }
  }
}

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function cleanUnusedHeadbands() {
  document.querySelectorAll('.js-headband:not(.slide-down)').forEach(hiddenHeadband => {
    hiddenHeadband.remove()
  })
}

function isHeadbandInTime(headband) {
  const now = new Date()
  const start = new Date(headband.dataset.start)
  const end = new Date(headband.dataset.end)

  if (now >= start && now <= end) {
    return true
  }
}

function getTotalHeadbandsHeight(sticky = undefined) {
  const selector = sticky === undefined ? '.js-headband.slide-down' : (sticky ? '.js-headband.slide-down.sticky' : '.js-headband.slide-down:not(.sticky)');
  const headbands = document.querySelectorAll(selector);
  const totalHeight = Array.from(headbands).reduce(
    (total, headband) => total + headband.clientHeight,
    0
  );
  return totalHeight;
}

function slideDownHeadband(headband, callback) {
  headband.classList.add('slide-down')
  headband.addEventListener('transitionend', callback, { once: true })
}

async function loopHeadbands(headbands) {
  for (const headband of headbands) {
    const unlockMetrics = headband.dataset.metrics;

    if (isHeadbandInTime(headband)) {
      showHeadbandCountdown(headband);
      removeMobilePadding();
      slideDownHeadband(headband, () => {
        updateHeaderPosition();
        updateSectionStyles();
      });

      const promoLinkElement = headband.querySelector('.js-promolink');
      if (unlockMetrics === 'true') {
        attachPromoLinkEvent(promoLinkElement);
      }
      initPromoLink(promoLinkElement);
    }
  }
}

function removeMobilePadding() {
  document.body.style.paddingTop = '0px';
}

function updateBurgerMenuPosition() {
  const allHeadbandsHeight = getTotalHeadbandsHeight()
  const burgerMenu = document.querySelector('.mobile-burger-menu.burgermenu-right');
  const burgerMenuPanel = document.querySelector('.burgermenu-panel');
  burgerMenu.style.paddingTop = `${allHeadbandsHeight + 60}px`
  burgerMenuPanel.style.paddingTop = `${allHeadbandsHeight + 60}px`
}

function updateSectionStyles() {
  const section = document.querySelector('section');
  const totalNonStickyHeight = getTotalHeadbandsHeight(false);
  if (section && isMobileDevice()) {
    updateBurgerMenuPosition()
    if (totalNonStickyHeight > 0) {
      section.style.transition = 'all 1s ease-in-out';
      section.style.paddingTop = `60px`
    }
  }
}

function attachPromoLinkEvent(promoLinkElement) {
  promoLinkElement.addEventListener('click', function () {
    sendHeadbandUtagEvent(promoLinkElement.closest('.headband'));
  });
}

function pushBody() {
  const totalStickyHeight = getTotalHeadbandsHeight(true);
  const totalNonStickyHeight = getTotalHeadbandsHeight(false);
  document.body.style.transition = 'all 1s ease-in-out'
  document.body.style.position = 'relative'

  if (isMobileDevice() && (totalStickyHeight === 0 || totalNonStickyHeight === 0)) {
    document.body.style.paddingTop = `${totalStickyHeight + 60}px`
  } else if (isMobileDevice() && (totalStickyHeight < 0 && totalNonStickyHeight < 0)) {
    document.body.style.paddingTop = `${totalStickyHeight}px`
  } else {
    document.body.style.paddingTop = `${totalStickyHeight}px`
  }
}

function updateHeaderPosition() {
  const header = document.getElementById('header');
  const totalAllHeight = getTotalHeadbandsHeight();
  const totalStickyHeight = getTotalHeadbandsHeight(true);
  const scrollY = window.scrollY;
  header.style.transition = 'all 1s ease-in-out'
  header.style.top = totalAllHeight + 'px';

  if (scrollY && totalStickyHeight >= 0) {
    header.style.top = totalStickyHeight + 'px';
  }
}

function setStickyHeadbandsPositions() {
  const stickyHeadbands = document.querySelectorAll(ACTIVE_STICKY_HEADBANDS_CLASS)
  stickyHeadbands.forEach((headband, index) => {
    const previousHeadband = stickyHeadbands[index - 1]
    if (previousHeadband) {
      headband.style.top = `${previousHeadband.clientHeight}px`
    }
  })
}

export default function init() {
  const allHeadbands = document.querySelectorAll('.js-headband')
  const stickyHeadbands = Array.from(allHeadbands).find(headband => headband.classList.contains('sticky'))

  if (!allHeadbands) return

  loopHeadbands(allHeadbands)
  cleanUnusedHeadbands()

  if (stickyHeadbands) {
    setStickyHeadbandsPositions()
    slideDownHeadband(stickyHeadbands, () => {
      pushBody()
    })
  }
}

window.addEventListener('scroll', updateHeaderPosition);

document.addEventListener('DOMContentLoaded', () => {
  requestIdleCallback(init)
})
