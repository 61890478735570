<template>
  <a class="popup-link" :href="href" @click="clickHandler()">
    <template v-if="text_link">
      {{ text_link }}
    </template>
    <template v-else>
      {{ apply_code_lang }} {{ promocode }}
    </template>
    <span class="s s-arrow-down-short-tail"></span>
  </a>
</template>
<script>
import { sendUtagEvent } from '../../../../../app/assets/javascripts/analytics/2_utag_events'

export default {
  name: "PopupLink",
  props: [
    "url_link",
    "text_link",
    "promocode",
    "url_cta",
    "parameters",
    'apply_code_lang',
    'popup_event_act'
  ],
  computed: {
    baseUrl() {
      return window.location.origin;
    },
    href() {
      if (this.parameters) {
        return location.protocol + '//' + location.host + location.pathname + '?' + this.parameters;
      } else if (this.url_link) {
        return this.url_link
      } else if (this.url_cta) {
        return this.baseUrl + this.url_cta

      }
      return '#'
    },
  },
  methods: {
    clickHandler() {
      this.$emit("popupClick");
      if(this.popup_event_act == "promo code")

        sendUtagEvent({
          data: {
            event_name: 'offers_apply',
            event_cat: 'offers',
            event_act: 'apply offer',
            event_lbl: this.promocode,
            event_purpose: 'booking',
            event_structure: 'body',
          },
        })

      if (this.promocode && !this.url_cta) {
        this.emitPromocodeToFastbookingEvent();
        this.closePopup();
        this.$emit("closePopup");
        return false;
      }
    },
    emitPromocodeToFastbookingEvent() {
      const event = new CustomEvent('setPromocodeInFastbooking', { detail: this.promocode });
      document.dispatchEvent(event);
    },
    closePopup() {
      const closePopupEvent = new CustomEvent('closePopup');
      window.dispatchEvent(closePopupEvent);
    }
  },
};
</script>
