
function init(blockId) {
  const $el = blockId ? $(`[data-block-id="${blockId}"]`) : $('.description-card-module')

  if (!$el.length) return false

  const desktop_limit = MQBE.current_state() === 'tablet' ? 3 : 3

  $el.each(function (index, el) {
    if (MQBE.current_state() !== 'mobile' && $(el).find('.info-item').length > desktop_limit) {
      if ($(el).find('.info-item').length > 1) {
        const $container = $(el).find('[data-swiper-container]')
        const $pagination = $(`<div class="info-module-basic-js-pagination${index} swiper-pagination-bullets"></div>`)


        $container.find('.navigation').prepend($pagination)
        $container.find('.navigation').addClass('active')
        $container.find('[data-swiper-wrapper]').addClass('swiper-wrapper').find('[data-swiper-slide]').addClass('swiper-slide')
        $container.find('img').each(function () {
          const $img = $(this)
          $img.attr('src', $img.attr('data-mobile'))
        })

        const swiperGalleryId = `${new Date().getTime()}`
        $container.attr('data-swiper', swiperGalleryId)

        new Swiper(`[data-swiper="${swiperGalleryId}"]`, {
          slidesPerView: 'auto',
          pagination: {
            el: `.info-module-basic-js-pagination${index}`,
            clickable: true,
            type: 'custom',
            renderCustom(swiper, current, total) {
              const width = (100 / total).toFixed(2)
              const left_pos = ((current * 100) / total - width).toFixed()
              return `<span class="swiper-pagination-progressbar" style="width:${width}%;margin-left:${left_pos}%"></span>`
            },
          },
          spaceBetween: 12,
          navigation: {
            nextEl: $container.parent().find('.nav-next').get(0),
            prevEl: $container.parent().find('.nav-prev').get(0),
          },
        })
      }
    }
  })
}

document.addEventListener('DOMContentLoaded', function () {
  init();
}, false);
