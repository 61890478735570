/**
 * @format
 */

/**
 * Page type detector
 *
 * Cache the value of data-page-type in html tag so no need
 * to constantly query the DOM.
 *
 * All the boolean funcs to check page type are based
 * in get_page_type helper
 * defined in app/helpers/application_helper.rb
 */

const htmlNode = document.querySelector('[data-page-type]')
const pageType = htmlNode && htmlNode.dataset && htmlNode.dataset.pageType

export const currentPageType = {
  get: () => pageType,
  isPresent: () => pageType !== null,
  isDashboard: () => pageType === 'dashboard',
  isBookings: () => pageType === 'bookings',
  isBenefits: () => pageType === 'benefits',
  isGeneric: () => pageType === 'page',
}
