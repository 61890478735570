import _filter from 'lodash/filter';
import _first from 'lodash/first';
import _tail from 'lodash/tail';
import _map from 'lodash/map';

let $block;
let CURRENT_DEVICE;

function init() {
  $block = $('.home-masonry-gallery');

  const MAX_VISIBLE_ITEMS = MQBE.current_state() === 'mobile' ? 1 : 3;
  const MODE_CLASSES = 'full double triple quadruple';
  const FULL_MODE_CLASS = 'full';

  CURRENT_DEVICE = MQBE.current_state() === 'tablet' || MQBE.current_state() === 'desktop' ? 'desktop' : 'mobile';

  // Ejemplo de controlPresence. Determina qué tipo de contenido mostrar en qué dispositivo
  // {
  //   "mobile": {"images":true, "videos":true, "virtual_tours":false, "webcam":false},
  //   "desktop": {"images":true, "videos":false, "virtual_tours":false, "webcam":true}
  // }
  if ($block.length <= 0) {
    return 
  } 
  let controlPresence = $block.data().presenceControl;
  let $masonryWrapper = $block.find('.masonry-wrapper');
  let $newLeftBlock = $('<ul class="left-block"></ul>');
  let $newRightBlock = $('<ul class="right-block"></ul>');
  let masonryModes = {
    1: 'double',
    2: 'triple',
    3: 'quadruple'
  };
  // Determinamos cuales son los elementos visibles
  let visibleItems = _filter($block.find('.block'), function (item) {
    return controlPresence[CURRENT_DEVICE][$(item).data().contentType];
  });

  // Construimos el nuevo masonry solo con los elementos visibles
  $newLeftBlock.append(fixMasonryImageRatio(_first(visibleItems)));
  let rightElements = _map(_tail(visibleItems), function (item, index) {
    index <= MAX_VISIBLE_ITEMS ? $(item).removeClass('hidden') : $(item).addClass('hidden');
    return item;
  });

  if (rightElements.length == 1) fixMasonryImageRatio(rightElements);
  $newRightBlock.append(rightElements);
  // Sustituimos el masonry viejo por el nuevo
  $masonryWrapper.removeClass(MODE_CLASSES).addClass(masonryModes[rightElements.length] || FULL_MODE_CLASS);
  $masonryWrapper.html('').append($newLeftBlock).append($newRightBlock);
}

function fixMasonryImageRatio(element) {
  let $el = $(element);
  let $source = $el.find('source');
  if ($source.length && $source.data('srcset') !== undefined) {
    $source.data('srcset', $source.data().srcset.replace('2:1', '1:1'));
  }
  return element;
}

function getNumberSlides() {
  let opposite_device = CURRENT_DEVICE === 'mobile' ? 'desktop' : 'mobile';
  let $gallery = $('.ib-gallery');
  let total_slides = $gallery.find('.gallery-item').length;
  let slides_to_remove = $gallery.find('.gallery-item.' + opposite_device + '-only').length;
  let total_to_show = total_slides - slides_to_remove;

  return total_to_show;
}

document.addEventListener('DOMContentLoaded', function () {
  init();
}, false);
