import { inputFilterOnlyNumbers } from '../utils/inputFilter.js';

(function ($, undefined) {
  'use strict';

  if (typeof IB === 'undefined') {
    window.IB = {};
  } else if (IB.contactUs !== undefined) {
    return;
  };

  var
    $doc = $(document),
    data = {
      $f: null,
      $country: null,
      $destination: null,
      $hotel: null,
      $reason: null,
      $destination_options: null,
      $hotel_options: null
    };

  function init() {
    IB.contactUs.data.$f = $(".contact-us form");
    IB.contactUs.data.$country = $(".contact-us form #country_id");
    IB.contactUs.data.$destination = $(".contact-us form #destination_id");
    IB.contactUs.data.$hotel = $(".contact-us form #hotel_id");
    IB.contactUs.data.$reason = $(".contact-us form #reason");
    IB.contactUs.data.$destination_options = IB.contactUs.data.$destination.find('option');
    IB.contactUs.data.$hotel_options = IB.contactUs.data.$hotel.find('option');
    IB.contactUs.data.$phone = IB.contactUs.data.$f.find('.js-input-telephone');

    setPrefix();

    // Init formz on phone field
    FORMZ.required_fields.init(IB.contactUs.data.$phone);
    // Filter input to allow only numbers
    IB.contactUs.data.$phone.length && inputFilterOnlyNumbers({ textBox: IB.contactUs.data.$phone.get(0) });

    markUsaAsMandatoryDestinyHotel();
    getUserData();
  };

  function setPrefix() {
    var utag_user_data = sessionStorage.getItem('utag_user'),
        $select = IB.contactUs.data.$f.find('.fz-field.prefix').find('select');

    if (utag_user_data === null) return false;

    utag_user_data = JSON.parse(utag_user_data);

    var country_code = utag_user_data.user_country_code !== undefined ? utag_user_data.user_country_code : utag_user_data.visitor_country_code;

    $select.val(country_code).change();
  }

  function markUsaAsMandatoryDestinyHotel(){
    var $country = IB.contactUs.data.$country;
    $country.find("option[data-country-code='USA']").attr("data-mandatory", "1");
  }

  function getData() {
    IB.contactUs.data
  }

  function display_dependant_selector($elem, $elem_dependent, $data) {
    show_children_options($elem, $elem_dependent, $data);
    remove_option_with_country_data($elem, $elem_dependent, $data);
  }

  // If changes a selector, empty value from its dependant selectors (f.e. country and destination)
  function remove_option_with_country_data($elem, $elem_dependent) {
    var $selected_elem_option = $elem_dependent.find("option:selected");

    if ($selected_elem_option) {
      if ($selected_elem_option.data("country-id") != $elem.value) {
        $elem_dependent.val("");
      }
    }
  }

  // Show children for a dependant selector based on current selector (f.e. country and destination or country and hotels)
  function show_children_options($elem, $elem_dependent, $data) {
    if ($($elem).val() != "") {
      var f =  $data.filter(function() {
                  return $(this).attr('data-'+$($elem).attr("id").replace("_", "-")) === $($elem).val() || $(this).val() === "";
                });
      $elem_dependent.empty().append(f).val("");
    }
    else {
      $elem_dependent.find("option").removeClass("hidden");
    }
  }

  // Mark selector for hotels and destinations as mandatory
  function mark_select_as_mandatory($selector, condition) {
    var $f = IB.contactUs.data.$f;

    if (condition){
      $selector.attr("data-parsley-required", "true");
      $selector.parent().find("label").addClass("required-mark");
    }
    else{
      $selector.removeAttr("data-parsley-required");
      $selector.parent().find("label").removeClass("required-mark");
      IB.contactUs.data.$f.find('#destination_id').parsley().validate();
      IB.contactUs.data.$f.find('#hotel_id').parsley().validate();
    }
    var $required_fields = $f.find('[data-parsley-required]:not([data-parsley-errors-container])');
    FORMZ.required_fields.init($required_fields);
  }

  // Take
  function getUserData(){
    var $f = IB.contactUs.data.$f;
    let user_params = getUserParams();
    let user_email = '';
    let user_crm_id = '';

    if (!jQuery.isEmptyObject(user_params)) {
      user_email = user_params.email
      user_crm_id = user_params.crm_id
    }

    if ($f.length > 0){
      $.ajax({
        dataType: "JSON",
        type: "GET",
        url: "/ajax_contents/contact/?email=" + user_email + "&crm_id=" + user_crm_id,
        success: function (response) {
          var data = response;

          $f.find("#first_name").val(data["name"]);
          $f.find("#last_name").val(data["surname"]);
          $f.find("#email").val(data["email"]);
          $f.find("#city").val(data["billing_city"]);
          $("#presented-phone").intlTelInput("setNumber", data["phone"]);

          if (data["commercial"] == true){
            $f.find("#_receive_offers").parent().hide();
          }
        }
      });
    }


  }


  function getUserParams(){
    let vars = {};
    let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  // ----------------------------------
  // Events
  // ----------------------------------
  $doc.on('change', ".contact-us form #reason", function () {
    var $country = IB.contactUs.data.$country,
        $destination = IB.contactUs.data.$destination,
        $hotel = IB.contactUs.data.$hotel,
        $selected_option = $("option:selected", this);

    if ($selected_option.data("mandatory-countries")){
      $.each($selected_option.data("mandatory-countries").split(","), function (index, value) {
        $country.find("option[data-country-code='" + value + "']").attr("data-mandatory", "1");
      });
    }
    else{
      $country.find("option").removeAttr("data-mandatory");
      markUsaAsMandatoryDestinyHotel();
    }

    var condition = $("option:selected", $country).attr("data-mandatory") == "1";

    mark_select_as_mandatory($destination, condition);
    mark_select_as_mandatory($hotel, condition);
  });

  $doc.on('change', ".contact-us form #country_id", function () {
    var $destination = IB.contactUs.data.$destination,
        $hotel = IB.contactUs.data.$hotel,
        condition = $("option:selected", this).attr("data-mandatory") == "1";

    display_dependant_selector(this, $destination, IB.contactUs.data.$destination_options);
    mark_select_as_mandatory($destination, condition);

    display_dependant_selector(this, $hotel, IB.contactUs.data.$hotel_options);
    mark_select_as_mandatory($hotel, condition);

  });

  $doc.on('change', ".contact-us form #destination_id", function () {
    var $country = IB.contactUs.data.$country,
        $hotel = IB.contactUs.data.$hotel,
        $selected_option = $("option:selected", this);

    if ($(this).val() != ""){
      $country.val($selected_option.data("country-id"));
      display_dependant_selector(this, $hotel, IB.contactUs.data.$hotel_options);
    }
    else{
      display_dependant_selector($country, $hotel, IB.contactUs.data.$hotel_options);
    }

  });

  $doc.on('change', ".contact-us form #hotel_id", function () {
    var $country = IB.contactUs.data.$country,
        $destination = IB.contactUs.data.$destination;

    $country.val($("option:selected", this).data("country-id"));
    $destination.val($("option:selected", this).data("destination-id"));
  });

  // ----------------------------------
  // initialize
  // ----------------------------------

  IB.contactUs = {
    init: init,
    data: data,
    getData: getData
  };

}(jQuery));

/* Auto-inits function */
$(document).ready(function () {
  if ($('.contact-us').length) IB.contactUs.init();
});
