import Vue from "vue/dist/vue.esm";

import PopupNotification from "./PopupNotification.vue";
document.addEventListener("DOMContentLoaded", () => {
  const VUE_LOCALE = document.querySelector("html").attributes.lang.value;

  // Vue Instance
  const VUE_POP_UP_NOTIFICATIONS = new Vue({
    el: "#vue-popup-notification",
    components: {
      'popupnotification':PopupNotification,
    }
  });

  Vue.prototype.$locale = VUE_LOCALE;
});
