/**
 * @format
 */

import { validatePhone } from '../../utils/validators.js';

document.addEventListener('DOMContentLoaded', function () {
  window.Parsley.addValidator('phoneonlynumbers', {
    requirementType: 'string',
    validateNumber: value => validatePhone(value),
    priority: 32,
    messages: {
      es: 'Formato de número de teléfono incorrecto',
      en: 'Incorrect telephone number format',
      de: 'Das Format der eingegebenen Telefonnummer ist ungültig',
      fr: 'Format de numéro de téléphone incorrect',
      it: 'Formato del numero di telefono errato',
      pt: 'Formato do número de telefone incorreto',
      ru: 'Неправильный формат номера телефона',
    },
  });
});
