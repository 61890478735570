/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-shadow-restricted-names */
(function ($, undefined) {

  if (typeof IB === 'undefined') {
    window.IB = {};
  } else if (IB.utag_eec_events !== undefined) {
    return;
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// -----------------------------------------------------------GENERIC IMPRESSION -------------------------------------------------------------------- //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function default_product_impression(){
    var product_impresion = {
      'product_impression_id': [],
      'product_impression_name': [],
      'product_impression_brand': [],
      'product_impression_variant': [],
      'product_impression_category': [],
      'product_impression_list': [],
      'product_impression_price': [],
      'product_impression_position': []
    };
    return product_impresion;
  }

  function default_product_click(){
    var product_click ={
      'product_id': "",
      'product_name': "",
      'product_category': "",
      'product_variant': [],
      'product_brand': "",
      'product_unit_price': "",
      'product_action_list': "",
      'product_position': [],
    }
    return product_click;
  }

  function default_promotion_impression(){
    var product_impresion = {
      'promotion_id': [],
      'promotion_name': [],
      'promotion_creative': [],
      'promotion_position': []
    };
    return product_impresion;
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------- ADVANCED PRODUCT IMPRESSION  ----------------------------------------------------------- //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

function advancedProductImpression(custom_product_impression, custom_product_category){

    // New list variables
    custom_product_impression['list_recommender_category'] = 'default'
    custom_product_impression['list_recommender_algorithm'] = 'default'

    // New product variables
    custom_product_impression['product_recommender_category'] =    Array( Array.isArray( custom_product_category ) ? custom_product_category.length : 1 ).fill('default')
    custom_product_impression['product_recommender_algorithm'] =   Array( Array.isArray( custom_product_category ) ? custom_product_category.length : 1 ).fill('default')
    custom_product_impression['product_recommender_new'] =   Array( Array.isArray( custom_product_category ) ? custom_product_category.length : 1 ).fill('false')
    custom_product_impression['product_recommender_conviction'] =   Array( Array.isArray( custom_product_category ) ? custom_product_category.length : 1 ).fill('')

  return custom_product_impression;

}

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// ----------------------------------------------------------- UTAG PROMOTION IMPRESSION ------------------------------------------------------------ //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function utag_link_product_impression(product_impression_info, product_name, event_action, non_interaction){
    try{
      var merged = {},
          base_info = {event_cat: "ecommerce", event_act: event_action.replace("_"," "), event_lbl: product_name};
          base_info['enh_action'] = event_action;
          if(non_interaction==true){
            base_info['non_interaction'] = 1;
          }
        $.extend(merged, base_info, product_impression_info);
      if (merged['non_interaction'] == 1) {
        // Initilize new visitor session id simulting GA sessions
        IB.VisitorSessionId.initNewVisitorSessionId();
      }
      utag.link(merged);
    }catch(err){
      console.error(err);
    }
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// ----------------------------------------------------------- WAIT FOR UTAG DATA ------------------------------------------------------------------- //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function eecWaitForUtagData(eec_info,counter){
    if (typeof utag !== "undefined") {
      setTimeout(function() {
        // Initilize new visitor session id simulting GA sessions
        IB.VisitorSessionId.initNewVisitorSessionId();

        utag.link(eec_info);
        //console.log(eec_info);
        //console.log("Contador: "+counter);
      },100);
    } else {
      setTimeout(function() {
        eecWaitForUtagData(eec_info,(counter + 1));
      }, 100);
    }
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// ----------------------------------------------------------- PRODUCT IMPRESSION ------------------------------------------------------------------- //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function product_impresion_event(product_impresion, data_eec_product, event_lbl){

    var price = (data_eec_product.product_impression_price == 99999 ? "" : data_eec_product.product_impression_price),
        event_lbl = (event_lbl.length == 0 ? data_eec_product.product_impression_name : event_lbl);

    var product_impression_list = $('#VUE_APP').length == 1 ? "destination | home destinations| hotel list" : data_eec_product.product_impression_list

    product_impresion['product_id'] = data_eec_product.product_impression_id;
    product_impresion['product_name'] = data_eec_product.product_impression_name;
    product_impresion['product_brand'] = data_eec_product.product_impression_brand;
    product_impresion['product_variant'].push(data_eec_product.product_impression_variant);
    product_impresion['product_category'] = data_eec_product.product_impression_category;
    product_impresion['product_action_list'] = product_impression_list;
    product_impresion['product_unit_price'] = String(price);
    product_impresion['product_position'].push(data_eec_product.product_impression_position);

    product_impresion = advancedProductImpression(product_impresion, data_eec_product.product_impression_category);
    utag_link_product_impression(product_impresion, event_lbl, 'product_click', false);
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// ----------------------------------------------------------- PROMOTION IMPRESSION ----------------------------------------------------------------- //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function promotion_impresion_event(promotion_impression, data_eec_product){
    promotion_impression['promotion_id'].push(data_eec_product.promotion_id);
    promotion_impression['promotion_name'].push(data_eec_product.promotion_name);
    promotion_impression['promotion_creative'].push(data_eec_product.promotion_creative);
    promotion_impression['promotion_position'].push(data_eec_product.promotion_position);

    product_impresion = advancedProductImpression(product_impresion, data_eec_product.product_impression_category);
    utag_link_product_impression(promotion_impression, data_eec_product.promotion_name, 'promo_click', false);
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// ----------------------------------------------------------- PRODUCT IMPRESSION SEARCH ------------------------------------------------------------ //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function product_impressions_search(){
    var product_impresion = default_product_impression(),
    elements = $(".hotel-list .completed .hotel, .list-view .hotel, #VUE_APP .hotel-list .hotel"),
    MaxProduct = 20;

    elements.each(function(pos) {
      var data_eec_product = $(this).data("utag-eec");
      var price_vue = $('#VUE_APP').length == 1 ? data_eec_product.product_impression_price : "";

      data_eec_product.product_impression_price = $(this).parent('.result-hotel').data('price');
      data_eec_product.product_impression_position = pos+1;

      var price = data_eec_product.product_impression_price == 99999 ? "" : data_eec_product.product_impression_price;

      $(this).attr("data-utag-eec",JSON.stringify(data_eec_product));
      $(this).find(".info-link").each(function(){
        $(this).attr("data-utag-price", price);
        $(this).attr("data-utag-position", pos);
      });

      product_impresion_list_name = $('#VUE_APP').length == 1 ? utag_data["page_section"] + " | " + utag_data["page_type"] +  "| " + "hotel list" : "search|booking result|hotel list"

      product_impresion['product_impression_id'].push(data_eec_product.product_impression_id);
      product_impresion['product_impression_name'].push(data_eec_product.product_impression_name);
      product_impresion['product_impression_brand'].push(data_eec_product.product_impression_brand);
      product_impresion['product_impression_variant'].push(data_eec_product.product_impression_variant);
      product_impresion['product_impression_category'].push(data_eec_product.product_impression_category);

      if ($('#VUE_APP').length == 1 ) 
      {
        product_impresion['product_impression_list'].push(product_impresion_list_name);
        product_impresion['product_impression_price'].push(price_vue);
      } 
        else 
      {
        product_impresion['product_impression_list'].push(data_eec_product.product_impression_list);
        product_impresion['product_impression_price'].push(price);
      }

      product_impresion['product_impression_position'].push(data_eec_product.product_impression_position);

      if((pos + 1) % MaxProduct == 0 || (pos+1) == elements.length){
        utag_link_product_impression(product_impresion, product_impresion_list_name,'product impression list', true);
        product_impresion = default_product_impression();
      }

      product_impresion = advancedProductImpression(product_impresion, data_eec_product.product_impression_category);

    });
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// ------------------------------------------------------------ PRODUCT IMPRESSION SLIDER ----------------------------------------------------------- //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function product_impressions_slider($el){
    $el.each(function(){
      if(!$(this).hasClass('utag_product_sent') && $(this).is(':visible')){
        var product_impresion = default_product_impression(),
            data_eec_product = $(this).data("utag-eec"),
            price = data_eec_product.product_impression_price == 99999 ? "" : data_eec_product.product_impression_price;
        $(this).addClass('utag_product_sent');
        product_impresion['product_impression_id'].push(data_eec_product.product_impression_id);
        product_impresion['product_impression_name'].push(data_eec_product.product_impression_name);
        product_impresion['product_impression_brand'].push(data_eec_product.product_impression_brand);
        product_impresion['product_impression_variant'].push(data_eec_product.product_impression_variant);
        product_impresion['product_impression_category'].push(data_eec_product.product_impression_category);
        product_impresion['product_impression_list'].push(data_eec_product.product_impression_list);
        product_impresion['product_impression_price'].push(price);
        product_impresion['product_impression_position'].push(data_eec_product.product_impression_position);

        product_impresion = advancedProductImpression(product_impresion, data_eec_product.product_impression_category);
        utag_link_product_impression(product_impresion, '', "product impression list", false);
      }
    })
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// -----------------------------------------------------------PROMOTION IMPRESSION SLIDER ----------------------------------------------------------- //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function promotion_impressions_slider($el){
    $el.each(function(){
      if(!$(this).hasClass('utag_promotion_sent') && $(this).is(':visible')){
        var promotion_impression = default_promotion_impression(),
            data_eec_product = $(this).data("utag-eec");
        $(this).addClass('utag_promotion_sent');
        promotion_impresion_event(promotion_impression, data_eec_product)
      }
    });
  }

// -------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------------- INITIALIZE ALL ------------------------------------------------------------- //
// -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function init(){

    $(document).on('click', '.hotel .btn-floating-fastbooking, .result-hotel .btn-with-arrow, a.utag-product-link, a.utag-recommended-hotel, .new-hotel a', function(){
      var product_impresion = default_product_click(),
          data_eec_product = $(this).data("utag-eec") === undefined ? $(this).closest('.hotel').data("utag-eec") : $(this).data("utag-eec");
      product_impresion_event(product_impresion, data_eec_product, "")
    });

    $(document).on('click', '.offer .js-book-btn, .b-offer-module-list .b-offer-module a.btn-with-arrow, a.utag-promo-link', function(){

        var promotion_impression = default_promotion_impression(),
            data_eec_product = $(this).data("ecc-product") == undefined ? $(this).data('ecc-promo') : $(this).data("ecc-product");
        data_eec_product = $(this).data("utag-eec") == undefined ? data_eec_product : $(this).data("utag-eec");
        promotion_impresion_event(promotion_impression, data_eec_product);
    });

    $(document).on('utag-menu-header-event','.utag-header-offers', function(){
      var eec_info = $("#vue-submenu .offers").data("utag-ecc-promo-list");
      eecWaitForUtagData(eec_info);
    });

  }

  IB.utag_eec_events = {
    init: init,
    product_impressions_search: product_impressions_search,
    product_impressions_slider: product_impressions_slider,
    promotion_impressions_slider: promotion_impressions_slider,
    eecWaitForUtagData: eecWaitForUtagData
  }


}(jQuery));

$(function () {
  IB.utag_eec_events.init();
});
