/**
 * @format
 */
import { onboardingStepper } from './onboarding_stepper';

window.addEventListener('load', () => {
  const modalSrc = document.querySelector('.js-onboarding-modal');

  if (!modalSrc) return;

  let swiperInstance;

  $.fancybox.open(modalSrc, {
    touch: false,
    beforeShow: () => {
      swiperInstance = onboardingStepper('.js-onboarding-swiper');
    },
    afterClose: () => {
      let action = `step ${swiperInstance.activeIndex + 1}`;
      IB.utag_events.sendUtagEvent({
        data: {
          event_name: '',
          event_cat: 'onboarding',
          event_act: action,
          event_lbl: 'close',
          event_purpose: '',
          event_structure: '',
        },
      });
    },
  });
});
