import watchJob from '../utils/watch_job.js';
import loaderSpinner from '../shared/loader_spinner';

(function ($, undefined) {

  if (typeof IB === 'undefined') {
    window.IB = {};
  }

  if (IB.dashboardBookingRow !== undefined) {
    return;
  }

  function init() {
    const $jobIdElement = document.querySelector('.js-bookings-job-id');

    if (!$jobIdElement) return;

    const bookingsJobId = $jobIdElement?.value;

    if (!bookingsJobId) return;
    showBookingsEmptyWrapper(false);
    const REPLACEMENT_PROPS = {
      active: 'html_replacements_active',
      inactive: 'html_replacements_not_active',
      canceled: 'html_replacements_canceled',
    };

    const $loaderContainer = document.querySelector('.js-bookings-container');
    const htmlProperties = [REPLACEMENT_PROPS.active, REPLACEMENT_PROPS.inactive, REPLACEMENT_PROPS.canceled];
    const $repeat_reservation = $("#repeat-reservation");

    // show loaderSpinner
    loaderSpinner.show({
      container: $loaderContainer,
      extraClass: 'mt-7 mb-7 b-loader--big'
    });

    // Build URL with jobID
    let prefix = document.querySelector('html').getAttribute('lang');
    prefix = prefix !== '/' ? prefix : '';
    const jobRequestUrl = `/${prefix}/bookings_jobs/${bookingsJobId}/`;

    const handleBookingsResponse = response => {
      if (!response || !response.length) return;

      const responseParsed = JSON.parse(response);

      // Concat all html_replacements
      htmlProperties.map((currentProperty) => {
        const currentHtml = responseParsed[currentProperty];

        if (!currentHtml) return;
        const currentHtmlParsed = JSON.parse(responseParsed[currentProperty]);
        // currentHtmlParsed is an object with all bookings which structure is { booking_id: booking_html }
        // so by obtaining all values and joining them we get all html in a unique string
        const bookingsHtmlList = Object.values(currentHtmlParsed);
        const currentBookingHtml = bookingsHtmlList.join('');

        let $currentBookingRow  = document.querySelector(`.dashboard-booking-row.${currentProperty}`);
        let $currentBookingContainer = $currentBookingRow.querySelector('.js-bookings-container');
        if (currentBookingHtml.length) {
          // Show bookings container if hidden
          if ($currentBookingRow.classList.contains('hidden')) {
            $currentBookingRow.classList.remove('hidden');
          }
          // Append html
          $currentBookingContainer.innerHTML = currentBookingHtml;
        }
      });
      initDashboardBookingRow();
    };

    watchJob({
      url: jobRequestUrl,
      timeout: 1000,
      onPartialResponse: handleBookingsResponse,
      onComplete: response => {
        loaderSpinner.hide({
          container: $loaderContainer,
        });

        // Check if there is any booking
        if (!response || !response.length || !Object.values(JSON.parse(response)).length) {
          showBookingsEmptyWrapper(true);
        } else {
          const responseParsed = JSON.parse(response);
          const hasHtmlContent = htmlProperties.some(property => {
            if (!responseParsed[property].length) return false;

            const htmlObject = JSON.parse(responseParsed[property]);
            return !!Object.values(htmlObject).length;
          });

          if (hasHtmlContent) {
            // Show bookings
            handleBookingsResponse(response);
          } else {
            showBookingsEmptyWrapper(true);
          }
        }
      },
    });

    function showBookingsEmptyWrapper(show){
      let $noBookingsBlock = document.querySelector('.empty-reservations-block');
      if($noBookingsBlock == null) return;
      show == true ? $noBookingsBlock.style.display= 'flex' : $noBookingsBlock.style.display= 'none';
    }
    function initDashboardBookingRow() {
      $('.repeat-reservation').fancybox({
        touch: false,
        beforeShow: function( instance, current ) {
          initDatepickerRepeatReservation();
          var $current = $(current.src);


          $current.find('.title').find('.name').text(instance.$lastFocus.closest('.js-repeat-block-cnt').find('.block').data('hotel-name'));
          $current.data('code', instance.$lastFocus.closest('div').find('form').data('code'));
        },
        afterClose: function() {
          $('.b-date-selection-input').data('dateRangePicker').clear();
          $('.b-date-selection-input').data('dateRangePicker').destroy();
          $repeat_reservation.removeClass('active-alert');
          $repeat_reservation.find('.dates-info').addClass('hidden');
          $repeat_reservation.find('.action').find('.btn-primary').addClass('disabled');
        }
      });

      $(document).on('click', '.close-repeat-booking', function(e) {
        e.preventDefault();

        parent.jQuery.fancybox.getInstance().close();
      });

      $repeat_reservation.on('hover', '.btn-primary.disabled', function(e) {
        e.preventDefault();
      });

      $(document).on('click', '.repeat-booking-button', function(e) {
        e.preventDefault();
        var bookingCode = $('#repeat-reservation').data('code'),
            $form = $('#repeat-booking-form-' + bookingCode),
            hotelName = $('#repeat-reservation .title .name').text(),
            checkInDate = $form.find('input.check_in_date').val(),
            checkOutDate = $form.find('input.check_out_date').val();

          $form.submit();
      });
    }
    function initDatepickerRepeatReservation() {
      var $b_date_selection_datepicker = $('.b-date-selection-datepicker');

      if (!$b_date_selection_datepicker.length) return false;



      var $input = $('.b-date-selection-input'),
          language = $('html').attr('lang'),
          date_format = $('html').attr('data-date-format'),
          start_of_week = $('html').attr('data-start-of-week'),
          is_mobile = MQBE.current_state() === 'mobile' ? true : false;

      if( is_mobile ) {
        date_format = date_format === "DD/MM/YYYY" ? "DD/MM/YY" : "MM/DD/YY";
      }

      var start_date = new moment().format(date_format);

      if ($('.active-booking').length) $('#repeat-reservation').find('.bootom-info').addClass('active');

      $input.dateRangePicker({
        format: date_format,
        startOfWeek: start_of_week,
        language: language,
        startDate: start_date,
        inline: true,
        minDays: 2,
        container: '.b-date-selection-datepicker',
        alwaysOpen:true,
        singleMonth: is_mobile,
        selectForward: true,
        stickyMonths: true,
        showTopbar: false,
        hoveringTooltip: false,
        showDateFilter: function(time, date) {
          var has_booking = false,
              first_reserved_day = false,
              last_reserved_day = false,
              bookings_days = [],
              booking_number = [];

          $('.active-booking').each(function (index, element) {
            var $el = $(element);
            bookings_days = bookings_days.concat($el.data('dates'));
            booking_number.push($el.data('booking-number'));
          });


          for (var index = 0; index < bookings_days.length; index++) {
            var booking = bookings_days[index];
            var booking_code = booking_number[index];
            var stay_from_date = moment.max(moment(booking.stay_from), moment()).format("YYYY-MM-DD");
            var stay_to_date = moment(booking.stay_to).format("YYYY-MM-DD");
            first_reserved_day = !moment(booking.stay_from).isAfter(moment(time), 'days') && !moment(booking.stay_from).isBefore(moment(time), 'days') ? "first-reserved-day" : "";
            last_reserved_day = !moment(booking.stay_to).isAfter(moment(time), 'days') && !moment(booking.stay_to).isBefore(moment(time), 'days') ? "last-reserved-day" : "";
            has_booking = moment().range(new Date(stay_from_date), new Date(stay_to_date)).contains(new Date(moment(time).format("YYYY-MM-DD")));
            if (has_booking) break;
          }
          var className = has_booking ? 'has-reserve' : '';
          return '<div class="number ' + className + ' '+ first_reserved_day +' '+ last_reserved_day +'" data-code="'+booking_code+'">' + date + '</div>';
        },
      }).on('datepicker-change', function(event, obj){

        var $action = $('#repeat-reservation').find('.action').find('.btn-primary'),
            code_array = [];

        if($action.hasClass('disabled')) $action.removeClass('disabled');

        setTimeout(function(){
          obj.box.find('.checked').each(function (index, element) {
            if ( $(element).find('.number').hasClass('has-reserve') ) {
              code_array.push($(element).find('.number').data('code'));
              is_mobile ? $('#repeat-reservation').addClass('active-alert').find('.alert-reserved-days').find('span').append(_.uniq(code_array).join(", ")) : $('#repeat-reservation').addClass('active-alert').find('.alert-reserved-days').append(_.uniq(code_array).join(", "));
              return false;
            } else {
              $('#repeat-reservation').removeClass('active-alert');
            }
          });

          var $dates_info = obj.box.closest('.calendar-cnt').find('.dates-info'),
              dates_checkin = is_mobile ? moment(obj.date1).format(date_format) : moment(obj.date1).format('ddd') + ', '+ moment(obj.date1).format(date_format),
              dates_checkout = is_mobile ? moment(obj.date2).format(date_format) : moment(obj.date2).format('ddd') + ', '+ moment(obj.date2).format(date_format),
              $nights = $dates_info.find('.dates-nights'),
              diff = moment(obj.date2).diff(moment(obj.date1), 'days'),
              text = diff > 1 ? $nights.data('other') : $nights.data('one'),
              bookingCode = $('#repeat-reservation').data('code');

          $('#check_in_date_' + bookingCode).val(moment(obj.date1).format('YYYY-MM-DD'));
          $('#check_out_date_' + bookingCode).val(moment(obj.date2).format('YYYY-MM-DD'));

          $dates_info.find('.dates-checkin').text(dates_checkin);
          $dates_info.find('.dates-checkout').text(dates_checkout);
          $nights.text(diff + ' ' + text);

          if ($dates_info.hasClass('hidden')) $dates_info.removeClass('hidden');
        }, 0);

      });
    }

  }

  IB.dashboardBookingRow = {
    init: init
  };

}(jQuery));
