/**
 * @format
 */

import { inputFilterOnlyNumbers } from '../utils/inputFilter.js';

$(function () {
  var $edit_user_page = $('.edit-user-page');

  if (!$edit_user_page.length) return;

  var $doc = $(document),
    $mail_lightbox = $('#change-mail'),
    $pass_lightbox = $('#change-password'),
    $mail_form = $mail_lightbox.find('.formz'),
    $pass_form = $pass_lightbox.find('.formz'),
    $mail_submit = $mail_form.find('.btn-primary'),
    $pass_submit = $pass_form.find('.btn-primary'),
    $new_email = $('#new-email'),
    $new_email_repeat = $('#new-email-repeat'),
    $old_pass = $('#old-pass'),
    $new_pass = $('#iberostar_api_user_password'),
    $new_pass_repeat = $('#iberostar_api_user_password_repeat'),
    $safe_word_lightbox = undefined,
    $safe_word_form = undefined,
    $safe_word_submit = undefined,
    $safe_word = undefined,
    $personal_data = $('.accordion-block.personal-data'),
    $notifications = $('.accordion-block.notifications'),
    $country = $('#country'),
    $postal_code = $('#postal-code'),
    $presented_phone = $('#presented-phone');

  // Render form by is_beneficiary?
  if ($('#change-safe-word').length) {
    $safe_word_lightbox = $('#change-safe-word');
    $safe_word_form = $safe_word_lightbox.find('.formz');
    $safe_word_submit = $safe_word_form.find('.btn-primary');
    $safe_word = $('#safe-word');
  }


  $doc.on('click', '.accordion-title', function (e) {
    e.preventDefault();
    $(this).closest('.accordion-block').toggleClass('opened');
  });

  $doc.on('click', '.cancel-user-edit, .updated-lightbox .btn-primary, .cancel-add-beneficiary', function (e) {
    e.preventDefault();
    parent.jQuery.fancybox.getInstance().close();
  });

  $doc.on('submit', '#personal-data', function (e) {
    if ($presented_phone.val() !== '') {
      var phonePrefix = $('.selected-dial-code').text().replace('+', ''),
        phoneNumber = $('#presented-phone').val(),
        phoneValue = phonePrefix + ' ' + phoneNumber;

      $('#phone').val(phoneValue);
      // console.log('phonePrefix', phonePrefix);
    }
  });

  $('.edit-lightbox').find('form').parsley();

  $('.edit-user-fancybox').fancybox({
    clickSlide: false,
    touch: false,
    afterClose: function (instance, current) {
      if (current.src === '#change-mail') {
        $mail_form[0].reset();
        $mail_form.parsley().reset();
        $mail_submit.prop('disabled', true);
      }
    },
  });

  $('.new-beneficiary-fancybox').fancybox({
    clickSlide: false,
    touch: false,
    beforeLoad: function(instance, current) {
      $("#beneficiary-presented-phone").intlTelInput();
    },
    afterLoad: function(instance, current) {
      $(':input','#new-beneficiary-form form').not(':button, :submit, :reset, :hidden').val('').removeAttr('checked').removeAttr('selected');
    },
    afterClose: function(){
      $("#new-beneficiary-form form").parsley().reset();
      $("#new-beneficiary-form .alert").remove();
    }
  });

  $doc.on('click', '#beneficiary-info-submit', function (e) {
    if ($('#beneficiary-presented-phone').val() !== '') {
      let phonePrefix = $('#new-beneficiary-form .selected-dial-code').text();
      let phoneNumber = $('#beneficiary-presented-phone').val();
      let phoneValue = `${phonePrefix} ${phoneNumber}`;
      $('#new-beneficiary-form #phone').val(phoneValue);
    }
  });

  $doc.on('click', '.edit-user-page .connect-btn', function (e) {
    e.preventDefault();
    var $this = $(this),
      connected = $this.hasClass('connected'),
      src = connected ? $this.data('disconnect-src') : $this.attr('href');

    $.fancybox.open({
      src: src,
      opts: {
        smallBtn: !connected,
        toolbar: false,
        clickOutside: connected ? false : 'close',
        clickSlide: connected ? false : 'close',
        touch: connected ? false : 'close',
      },
    });

    if (connected) {
      window.location.replace($this.data('disconnect-url'));
    }
  });

  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode,
    event = isIE11 ? 'change' : 'input';

  $personal_data.add($notifications).on(event, function () {
    enablePersonalDataForm($(this));
  });

  $doc.on(event, '#personal-data input', function (e) {
    $(this).addClass('edited');
  });

  $personal_data.add($notifications).on('submit', function (e) {
    window.onbeforeunload = function () {
      // blank function do nothing
    };
    if (!$(this).find('form').parsley().isValid()) {
      e.preventDefault();
      window.onbeforeunload = function () {
        return 'Did you save your stuff?';
      };
    }
  });

  $personal_data.add($notifications).on('click', '.clear-form', function (e) {
    e.preventDefault();
    var $this = $(this);

    if ($this.hasClass('disabled')) return;

    $this.closest('form').trigger('reset');
    $presented_phone.intlTelInput('setNumber', $presented_phone.val());

    $this.addClass('disabled');
    $this.closest('form').parsley().reset();
    $this.closest('.accordion-block').removeClass('edited').find('.btn-primary').prop('disabled', true);
    $('input.edited').removeClass('edited');

    if (!$personal_data.hasClass('edited') && !$notifications.hasClass('edited')) {
      window.onbeforeunload = function () {
        // blank function do nothing
      };
    }
  });

  $($new_email)
    .add($new_email_repeat)
    .keyup(function () {
      if ($new_email.val() && $new_email_repeat.val() && $mail_submit.is(':disabled')) {
        $mail_submit.prop('disabled', false);
      }
    });

    $($old_pass)
    .add($new_pass)
    .add($new_pass_repeat)
    .keyup(function () {
      if ($old_pass.val() && $new_pass.val() && $new_pass_repeat.val() && $pass_submit.is(':disabled')) {
        $pass_submit.prop('disabled', false);
      }
    });

  if ($safe_word) {
    $($safe_word)
      .keyup(function () {
        if ($safe_word.val() && $safe_word_submit.is(':disabled')) {
          $safe_word_submit.prop('disabled', false);
        }
      });
  }

  // Init formz on phone field
  FORMZ.required_fields.init($presented_phone);
  // Filter input to allow only numbers
  $presented_phone && inputFilterOnlyNumbers({ textBox: $presented_phone.get(0) });

  $presented_phone.intlTelInput();

  var presented_phone_val_for_reset = $presented_phone.val();

  $presented_phone.on('countrychange', function (e, countryData) {
    if ($presented_phone.val() !== '') {
      enablePersonalDataForm($personal_data);
    }
  });

  $doc.on('submit', '#user-preferences', function (e) {
    var $this = $(this),
      bedValue = $('input[name=iberostar_api_user\\[bed_type\\]]:checked').val(),
      bedEventLabel = $('#bed-types').data('bedTypes')[bedValue],
      eventAction = 'Mis preferencias de viaje';
    (smokingRoomEventLabel = $('input[name=iberostar_api_user\\[smoking_room\\]]:checked').val() === 'true' ? 'Fumador' : 'No Fumador'),
      (floorRoomEventLabel = $('input[name=iberostar_api_user\\[high_floor_room\\]]:checked').val() === 'true' ? 'Planta Alta' : 'Planta Baja');
  });

  function loadGoogleMapsScript() {
    var lang = $('html').attr('lang') || $('html').attr('xml:lang') || 'en',
      script = document.createElement('script'),
      url = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&language=' + lang;
    if (GMapz.APIKEY !== '') {
      url += '&key=' + GMapz.APIKEY;
    }
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);
  }

  function checkGooglePostalCode() {
    if ($country.val() === '' || $postal_code.val() === '') return;

    var geocoder = new google.maps.Geocoder(),
      city = '',
      $city_input = $('#city');

    geocoder.geocode(
      {
        componentRestrictions: {
          country: $country.val(),
          postalCode: $postal_code.val(),
        },
      },
      function (results, status) {
        if (status === 'OK') {
          var cities = results[0].postcode_localities;
          if (cities) {
            $city_input.parent().find('select').remove();
            //turn city into a dropdown if necessary
            var $select = $(document.createElement('select'));

            $select.on('change', function () {
              $city_input.val($(this).val());
            });

            $.each(cities, function (index, locality) {
              var $option = $(document.createElement('option'));
              $option.html(locality);
              $option.attr('value', locality);
              // if(city == locality) {
              //   $option.attr('selected','selected');
              // }
              $select.append($option);
              $select.change();
            });
            $select.attr('id', 'city-select');
            $city_input.hide();
            $city_input.parent().append($select);
          } else {
            var address_components = results[0].address_components;
            $.each(address_components, function (index, component) {
              var types = component.types;
              $.each(types, function (index, type) {
                if (type == 'locality') {
                  city = component.long_name;
                }
              });
            });
            $city_input.parent().find('select').remove();
            if (!$city_input.is(':visible')) $city_input.show();
            $city_input.val(city);
          }
        } else {
          $city_input.parent().find('select').remove();
          $city_input.val('');
          if (!$city_input.is(':visible')) $city_input.show();
        }
      }
    );
  }

  function enablePersonalDataForm($el) {
    if (!$el.hasClass('edited')) {
      $el.find('.action').find('.clear-form').removeClass('disabled');
      $el.find('.action').find('.btn-primary').prop('disabled', false);
      $el.addClass('edited');
      window.onbeforeunload = function () {
        return 'Did you save your stuff?';
      };
    }
  }

  if (typeof google === 'undefined') loadGoogleMapsScript();

  $country.on('change', function () {
    checkGooglePostalCode();
  });

  $postal_code.blur(function () {
    checkGooglePostalCode();
  });
});
