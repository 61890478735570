/**
 * @format
 */

import { resolve } from 'core-js/fn/promise';
import tooltipSequence from '../elements/tooltip_sequence';
import { isVisibleInXAxis } from '../utils/isVisibleInViewport';

// Listen to load event to ensure that html tooltip templates are rendered
window.addEventListener('load', () => {
  const isMobile = MQBE.current_state() === 'mobile';
  const onboardingTutorial = document.querySelector('.js-onboarding-tutorial');
  const initTutorialLinks = document.querySelectorAll('.js-init-onboarding-tutorial');
  const headerElem = document.querySelector('header.private');
  let beforeTooltipChange;

  if (!onboardingTutorial) return;

  const showOnInit = onboardingTutorial.dataset.showOnInit === 'true';
  const menuSelector = isMobile ? '.js-mobile-burger-menu ' : '';

  const sequence = [
    {
      selector: '.js-tutorial-tooltip-user',
      htmlContentSelector: '#tutorial-tooltip-user-template',
      placement: 'bottom',
    },
    {
      selector: `${menuSelector}.js-tutorial-tooltip-destinations`,
      htmlContentSelector: '#tutorial-tooltip-destinations-template',
      placement: 'bottom',
    },
    {
      selector: `${menuSelector}.js-tutorial-tooltip-bookings`,
      htmlContentSelector: '#tutorial-tooltip-bookings-template',
      placement: 'bottom',
    },
    {
      selector: `${menuSelector}.js-tutorial-tooltip-benefits`,
      htmlContentSelector: '#tutorial-tooltip-benefits-template',
      placement: 'bottom',
    },
    {
      selector: `${menuSelector}.js-tutorial-tooltip-contact`,
      htmlContentSelector: '#tutorial-tooltip-contact-template',
      placement: 'bottom',
    },
    {
      selector: '.js-tutorial-tooltip-points',
      htmlContentSelector: '#tutorial-tooltip-points-template',
      placement: isMobile ? 'bottom' : 'right',
    },
    {
      selector: '.js-tutorial-tooltip-starcollection',
      htmlContentSelector: '#tutorial-tooltip-starcollection-template',
      placement: 'bottom',
    },
    {
      selector: '.js-tutorial-tooltip-selectedresorts',
      htmlContentSelector: '#tutorial-tooltip-selectedresorts-template',
      placement: 'bottom',
    },
    {
      selector: '.js-tutorial-tooltip-trendsetter',
      htmlContentSelector: '#tutorial-tooltip-trendsetter-template',
      placement: 'bottom',
    },
    {
      selector: isMobile ? '.js-tutorial-tooltip-fastbooking-mobile' : '.js-tutorial-tooltip-fastbooking',
      htmlContentSelector: '#tutorial-tooltip-fastbooking-template',
      placement: isMobile ? 'bottom' : 'top',
    },
  ];

  const toggleTutorialLinks = show => {
    initTutorialLinks.forEach(initLink => {
      initLink.setAttribute('aria-hidden', !show);
    });
  };

  const sendTooltipUtagEvent = ({ category = 'tooltip', step, label }) => {
    if (!step || !label) return;

    event_step = `step ${step}`;
    IB.utag_events.sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        step: event_step,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    });
  };

  if (isMobile) {
    const fastbookingWrapper = document.querySelector('#fastbooking-mobile');
    const mobileMenuToggle = document.querySelector('.js-mobile-menu-toggle');
    const fastbookingButton = document.querySelector('.open-mobile-panel[href="#fastbooking-mobile"]');
    const fastbookingCloseButton = fastbookingWrapper.querySelector('[data-close-panel]');

    beforeTooltipChange = (currentIndex, nextIndex) => {
      const nextTooltipSelector = sequence[nextIndex].selector;
      const nextTooltip = document.querySelector(nextTooltipSelector);
      const mobileBurgerMenu = nextTooltip.closest('.js-mobile-burger-menu');
      const isInsideMobileMenu = !!mobileBurgerMenu;
      const isMobileMenuOpen = [...document.querySelector('html').classList].some(className => className.includes('opened-'));
      const swiperContainer = nextTooltip.closest('.swiper-container');
      const inFastbooking = !!nextTooltip.closest('.js-mobile-fastbooking');
      const isFastbookingOpen = fastbookingWrapper?.classList.contains('opened');
      const inHeader = !!nextTooltip.closest('header.private');
      const isHeaderOpen = headerElem?.classList.contains('nav-down');

      if (inHeader && !isHeaderOpen) {
        // Show header
        IB.mobileHeader.show();
      }

      if ((isInsideMobileMenu && !isMobileMenuOpen) || (!isInsideMobileMenu && isMobileMenuOpen)) {
        // Open/close burger menu
        mobileMenuToggle && mobileMenuToggle.click();
      }

      if (inFastbooking && !isFastbookingOpen) {
        // open fastbooking
        fastbookingButton.click();
      } else if (!inFastbooking && isFastbookingOpen) {
        // close fastbooking
        fastbookingCloseButton.click();
      }

      // Open mobile menu if is inside mobile menu and its not opened
      if (isInsideMobileMenu && !isMobileMenuOpen) {
        return new Promise(resolve => {
          // Listen to open transition finish
          mobileBurgerMenu.addEventListener(
            'transitionend',
            () => {
              resolve();
            },
            { once: true }
          );
        });
      }

      // Move swiper if is inside swiper and its not visible
      if (swiperContainer) {
        const swiperInstance = swiperContainer.swiper;
        const swiperWrapper = swiperInstance.wrapperEl;
        // const isTooltipReferenceVisibleInviewport = isVisibleInXAxis(nextTooltip);
        // const isTooltipReferenceVisibleInSlider = nextTooltip.closest('.swiper-slide-active');

        let sliderCurrentActiveIndex;
        let referenceSlideIndex;

        for (let [key, value] of Object.entries(swiperInstance.slides)) {
          if (key !== 'length' && isVisibleInXAxis(value)) {
            sliderCurrentActiveIndex = key;
          }

          if (key !== 'length' && value.querySelector(nextTooltipSelector)) {
            referenceSlideIndex = key;
          }
        }

        const isTooltipReferenceVisible = sliderCurrentActiveIndex === referenceSlideIndex;

        if (!isTooltipReferenceVisible) {
          swiperInstance.slideTo(referenceSlideIndex);

          return new Promise(resolve => {
            // Listen to slide transition finish
            swiperWrapper.addEventListener(
              'transitionend',
              () => {
                resolve();
              },
              { once: true }
            );
          });
        }
      }

      // Open/close fastbooking if is inside fastbooking and its not visible
      if (inFastbooking && !isFastbookingOpen) {
        return new Promise(resolve => {
          // Listen to fastbooking opening transition finish
          fastbookingWrapper.addEventListener(
            'transitionend',
            () => {
              resolve();
            },
            { once: true }
          );
        });
      }

      // Shows header if is inside header and its not visible
      if (inHeader && !isHeaderOpen) {
        return new Promise(resolve => {
          // Listen to header opening transition finish
          headerElem.addEventListener(
            'transitionend',
            () => {
              resolve();
            },
            { once: true }
          );
        });
      }
    };
  }

  const onboardingToooltipTutorial = new tooltipSequence(sequence, {
    showOnInit: false,
    mandatory: showOnInit, // If showOnInit true then its first time, so sequence is mandatory
    ...(beforeTooltipChange && { beforeChange: beforeTooltipChange }), // Adds property only if beforeTooltipChange is defined
    // mobileBreakpoint: 450,
    // centerOnMobile: false,
    scrollOffset: (document.querySelector('.header.private')?.offsetHeight + (isMobile ? 10 : 30)) * -1,
    onStart: () => {
      // Hide "back to tutorial" link
      toggleTutorialLinks(false);
    },
    onTooltipShow: ({ activeIndex, lastIndex }) => {
      if (lastIndex !== -1) {
        lastIndex < activeIndex ? sendTooltipUtagEvent({ step: lastIndex + 1, label: 'next' }) : sendTooltipUtagEvent({ step: lastIndex + 1, label: 'previous' });
      }

      sendTooltipUtagEvent({ step: activeIndex + 1, label: 'view' });
    },
    onInterrupt: ({ lastIndex }) => {
      // Show "back to tutorial" link
      toggleTutorialLinks(true);

      sendTooltipUtagEvent({ step: lastIndex + 1, label: 'close' });
    },
    onComplete: sequenceInstance => {
      // Scroll top
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // Show "back to tutorial" link
      toggleTutorialLinks(true);

      sequenceInstance.setMandatory(false);

      // Change flag in the user's data
      $.ajax({
        dataType: 'script',
        type: 'POST',
        url: '/ajax_contents/set_tooltips_viewed',
      });
    },
  });

  const initTutorial = ({ isMandatory } = {}) => {
    typeof isMandatory !== 'undefined' && onboardingToooltipTutorial.setMandatory(isMandatory);

    onboardingToooltipTutorial.start();

    // Preload explore module images
    IB.lazyImg.loadAllImagesSelector($('.js-onboarding-preload-img'));
  };

  // Init tutorial links behavior
  initTutorialLinks.forEach(initLink => {
    initLink.addEventListener('click', () => {
      initTutorial({ isMandatory: false });
    });
  });

  // Init tutorial on page load if set
  if (showOnInit) {
    headerElem.offsetHeight ? initTutorial() : setTimeout(initTutorial, 0);
  }

  // Public method to init tutorial
  IB.initOnboardingTutorial = initTutorial;
});
