/* eslint-disable no-useless-escape */
import cookieCheck from '3rd-cookie-check';

// Variables to be sent to the Auth0 login view
const extraMetrics = [
  'campaign_content',
  'campaign_cp_origin',
  'campaign_cp',
  'campaign_medium',
  'campaign_name',
  'campaign_source',
  'campaign_term',
  'cookies_consent', //
  'device_type',
  'hotel_headquarter_final',
  'hotel_headquarter_origin',
  'page_language',
  'page_platform',
  'promo_is_miiberostar',
  'promo_is_resident',
  'user_id_horizons', //
  'user_id',
  'user_level',
  'visitor_country_code',
  'visitor_country',
  'visitor_depth', //
  'visitor_has_reservation',
  'visitor_headquarter_market',
  'visitor_id', //
  'visitor_internal_market',
  'visitor_is_buyer',
  'visitor_is_logged', //
  'visitor_is_repeater',
  'visitor_personalized_tratement',
  'visitor_recive_advertising',
  'visitor_recurrence',
  'visitor_session_count',
  'visitor_session_id',
  'visitor_type',
]


function extractProperties(obj, keys) {
  const newObject = {}
  keys.forEach(function (key) {
    if (obj.hasOwnProperty(key)) {
      newObject[key] = obj[key]
    }
  })
  return newObject
}

$( document ).ready(function() {

  let current_user = $.cookie('logged_messages');

  // Tras un login en auth0 puede entrar por aquí al no tener un current_user en rails
  let init_session;

  if(document.querySelector("#init_session")){
    init_session =  document.querySelector("#init_session").value;
  }else{
    init_session = false;
  }

  // Si hay sesión iniciada y estamos en la home pública (/) directamente redireccionamos al área privada (/mi-iberostar)
  // Esto resuelve el aterrizaje desde CRS en CMS
  let is_home = document.querySelector("#is_home") && document.querySelector("#is_home").value==='true';
  let jump_from_crs = readCookie("jump_from_crs") && readCookie("jump_from_crs")==='true';
  let private_url = document.querySelector("#private_url") && document.querySelector("#private_url").value;

  if( private_url && jump_from_crs && is_home ){
    setCookie("jump_from_crs", false, -1)
    window.location.href = private_url;
  }

  // SSO
  let clientAuth0 = new auth0.WebAuth({
    domain: AUTH0_CONFIG.domain,
    clientID: AUTH0_CONFIG.clientId,
    redirectUri: AUTH0_CONFIG.redirectUri,
    audience: AUTH0_CONFIG.audience,
    connection: AUTH0_CONFIG.connection,
    responseType: 'token id_token',
    scope: 'openid profile user_metadata'
  });

  cookieCheck(1000).then(function(resultCookieCheck) {
    let current_path = window.location.pathname
    let is_home_page = current_path === '/'
    // Verificamos si viene de APP para no aplicar el checkSession
    let is_app = readCookie("in_app") === 'true';

    // console.log("supported = " +  resultCookieCheck.supported );
    // console.log("ios = " +  iOS() );

    // Si hay sesión de rails pero se pierde o cierra la de auth0
    if( current_user && !is_app && resultCookieCheck.supported){
      clientAuth0.checkSession({
        audience: AUTH0_CONFIG.audience,
        scope: 'read:current_user'
      }, function (err) {
        if(err){
          console.log("Error checkSession auth0: " + err.description);
          //Forzamos el cierre de sesión (este caso pretende forzar el cierre por ejemplo si el usuario ha cerrado sesión en CRS de fomra que vayan acompasados)
          let logout_url = window.location.protocol + "//" + window.location.host + '/auth/logout';
          window.location.href  = logout_url;
        }
      });
    }else if( !current_user && checkConfig() && !init_session && !is_home_page){
      // No debe entrar en la home
      console.log("sso.js");
      clientAuth0.checkSession({}, function (err, authResult) {
        if(err){
          console.log("Error checkSession auth0: " + err.description);
        }else if(authResult){
          // Si no tiene current_user, no va a mostrar el formulario de postregisto document.cookie['user_metadata'] y obtiene valores de auth0 authResult
          const csrfToken = document.querySelector('[name=csrf-token]').content;
          fetch('/sso',{
            method: "POST",
            body: JSON.stringify(authResult),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              'X-CSRF-Token': csrfToken
            }
          })
          .then(r => r.json())
          .then(data => {
            if(data.return_to){
              console.log(data.return_to);
              window.location.href = data.return_to;
            }else{
              window.location.href = "/"
            }
          })
          .catch(error => {
            console.log("SSO error: " + error);
          });
        }
      });
    }
  });

  function checkConfig(){
    return AUTH0_CONFIG.domain.length>1 && AUTH0_CONFIG.clientId.length>1 && AUTH0_CONFIG.redirectUri.length>1 && AUTH0_CONFIG.audience.length>1
  }

  if($("#show_login_auth0").val() === "true"){
    login();
  }

  // Login
  $("#login_auth0").click(function(e){
    e.preventDefault();
    clientAuth0.checkSession({}, function (err) {
      if(err){
        console.log("User not logged needs to login");
        login();
      }else{
        console.log("User logged go to private page");
        window.location.href =  $("body").data("return-to") !== undefined ? $("body").data("return-to") : "/";
      }
    });
  });

  function login(){

    let webAuth = new auth0.WebAuth({
      domain: AUTH0_CONFIG.domain,
      clientID: AUTH0_CONFIG.clientId,
      redirectUri: AUTH0_CONFIG.redirectUri,
      audience: AUTH0_CONFIG.audience,
      responseType: 'token id_token',
      scope: 'openid profile user_metadata'
    });

    let locale = document.documentElement.lang;
    let environment = document.querySelector("#environment") ? document.querySelector("#environment").value : '';
    if(environment === 'development'){
      environment = AUTH0_CONFIG.connection === 'STAGING-TC' ? 'pre' : AUTH0_CONFIG.connection.toLowerCase().replace('-tc','');
    }

    let backUrl = window.location.href.
      replaceAll(/([\?&])open=[^&$]+&?/g, '$1').
      replaceAll(/([\?&])show_login=[^&$]+&?/g, '$1'). // We replace the open=login parameters to avoid redirection loops
      replaceAll(/[\?&]$/g, '') // And then replace & at the end
    console.log(backUrl);

    webAuth.authorize({
      ui_locales: locale,
      application: "CMS",
      back: backUrl,
      environment: environment,
      redirectUri: AUTH0_CONFIG.redirectUri,
      update_metadata: document.location.origin + "/update_app_metadata",
      responseType: 'code',
      extraMetric: extractProperties(window.utag_data, extraMetrics),
    })
  }

  //Change password
  $("#change_password").click(function(e){
    e.preventDefault();
    let webAuth = new auth0.WebAuth({
      domain: AUTH0_CONFIG.domain,
      clientID: AUTH0_CONFIG.clientId,
      redirectUri: AUTH0_CONFIG.redirectUri,
      audience: AUTH0_CONFIG.audience,
      responseType: 'token id_token',
      scope: 'openid profile user_metadata'
    });
    let environment = document.querySelector("#environment") ? document.querySelector("#environment").value : '';
    
    let locale = readCookie("locale");
    if(!locale){
      locale = document.documentElement.lang;
    }

    auth0_update_metadata(locale, environment);
    let email = $('#current_user_email').val();
    webAuth.changePassword({
      ui_locales: locale,
      application: "CMS",
      connection: AUTH0_CONFIG.connection,
      environment: environment,
      email: email
    }, function(err){
      if (err){
        console.log("ERROR: " + err.description);
        $.fancybox.open(document.querySelector("#change-password-error"));
      }else{
        $.fancybox.open(document.querySelector("#change-password-success"));
      }
    })
  })

  function auth0_update_metadata(locale, environment){
    if(locale === null || locale === undefined){
      locale = document.documentElement.lang;
    }
    
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    fetch('/update_app_metadata',{
      method: "POST",
      body: JSON.stringify({locale: locale, environment: environment}),
      headers: {"Content-type": "application/json; charset=UTF-8", 'X-CSRF-Token': csrfToken}
    })
    .then(r => r.json())
    .then(() => {
      console.log("Update metadata OK");
    })
    .catch(error => {
      console.log("Update metadata error: " + error);
    });
  }

  // function iOS() {
  //   return [
  //     'iPad Simulator',
  //     'iPhone Simulator',
  //     'iPod Simulator',
  //     'iPad',
  //     'iPhone',
  //     'iPod'
  //   ].includes(navigator.platform)
  //   // iPad on iOS 13 detection
  //   || (navigator.userAgent.includes("Mac"))
  // }

});
