import { currentPageType } from '../utils/page_type_detector';

function checkPageWithModal(popUp) {
  const PAGES_WITH_MODAL = ['dashboard', 'bookings', 'benefits']
  const currentPage = currentPageType.get()
  const namePopUpCookie = `pop_up_${currentPage}`
  
  if (PAGES_WITH_MODAL.includes(currentPage) && !readCookie(namePopUpCookie)) {
    popUp.classList.remove('hidden')
    
    $.fancybox.open(popUp, {
      beforeClose: function() {
        popUp.classList.add('hidden')
      }
    })
    setCookie(namePopUpCookie, true, 1)
  }
}

document.addEventListener('DOMContentLoaded', ()=> {
  const popUp = document.querySelector('.popup')
  popUp && checkPageWithModal(popUp)
})