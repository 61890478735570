/**
 * @format
 */

import { REGEX_LIB } from './constants';

/**
 * Restricts input for the given textBox to the given inputFilter
 *
 * @param {dom object} textBox - input dom object
 * @param {function} inputFilter - filtering function
 * @param {array} events - array of events to listen on input
 */
function inputFilter({ textBox, inputFilter, events = ['input', 'keydown'] }) {
  if(textBox === undefined) return ;
  events.forEach(function (event) {
    textBox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = '';
      }
    });
  });
}

/**
 * Filters a text input to allow only numbers
 * @param {dom object} textBox - input dom object
 * @param {array} events - array of events to listen on input
 */
export const inputFilterOnlyNumbers = ({ textBox, events }) => {
  inputFilter({
    textBox,
    inputFilter: value => REGEX_LIB.onlyNumbers.test(value),
    events,
  });
};
