$( document ).ready(function() {
  if(document.querySelector("#init_session") && document.querySelector("#init_session").value!== 'null'){
    let value =  document.querySelector("#init_session").value;
    let data = JSON.parse(value);
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    console.log("init_session.js:"+JSON.stringify(data));
    //try {
    fetch("/sessions/",{
      method:"POST",
      body: JSON.stringify({'iberostar_api_user_session': data}),
      headers:{
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-CSRF-Token': csrfToken
      }
    })
    .then(r => r.json())
    .then(data => {
      console.log("SESSION OK: " + data.return_to);
      if(data.return_to){
        window.location.href = data.return_to;
      }else{
        window.location.href =  $("body").data("return-to") !== undefined ? $("body").data("return-to") : "/";
      }
    }).catch(error => {
      console.log("SESSION error: " + error);
      window.location.href =  $("body").data("return-to") !== undefined ? $("body").data("return-to") : "/";
    });
    // }catch (error) {
    //   console.log("SESSION error excepction: " + error);
    //   window.location.href = "/";
    // }
  }

  //Prueba para tratar de solucionar el problema de cross origin en incógnito
  // var auth0Client = new auth0.WebAuth({
  //   domain: AUTH0_CONFIG.domain,
  //   clientID: AUTH0_CONFIG.clientId,
  // });
  //auth0Client.crossOriginVerification();
});
