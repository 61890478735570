function init() {
  if (MQBE.current_state() === 'mobile') {
    $('.information-horizontal-module-item').each(function (index, element) {
      const $this = $(element)
      const $container = $this.find('.card-container')

      const desc_height = $container.find('.desc').length ? $container.find('.desc').height() : 0
      const title_height = $container.find('.block-title').length ? $container.find('.block-title').height() : 0
      const pretitle_height = $container.find('.pretitle').length ? $container.find('.pretitle').height() : 0

      if (desc_height + title_height + pretitle_height <= $container.height()) return

      $this.addClass('trunked')
    })
  }
}

$(document).on('click', '.information-horizontal-module-item .action a', function (e) {
  e.preventDefault()
  $(this).closest('.information-horizontal-module-item').toggleClass('opened')
})

document.addEventListener('DOMContentLoaded', function () {
  init();
}, false);
